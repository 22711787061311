<template>
  <vxe-column
    :field="dynamicField.field_name"
    :title="dynamicField.title"
    :edit-render="editRender"
    show-overflow="ellipsis"
    width="300"
  >
    <template #default="{ row }">
      <span>{{ formatValue(row[dynamicField.field_name]) }}</span>
    </template>
    <template #edit="scope">
      <vxe-select v-model="scope.row[dynamicField.field_name]" transfer>
        <vxe-option v-for="item in items" :key="item.key" :value="item.key"
                    :label="item.value"/>
      </vxe-select>
    </template>
  </vxe-column>
</template>

<script>
import axiosInstance from "@/axiosInstance";

export default {
  name: "Field",
  props: {
    dynamicField: {
      type: Object,
      require: true,
    },
    tableRef: {
      require: true,
    }
  },
  data: () => ({
    editRender: {
      autofocus: '.myinput'
    },
    items: [],
  }),
  created() {
    axiosInstance.get('entity/list', { params: { entity: [this.dynamicField.extra_options.entity] } }).then((response) => {
      this.items = response.data;
    });
  },
  computed: {
    isMultiple() {
      return this.dynamicField.extra_options.multiple
    }
  },
  methods: {
    formatValue(value) {
      if (value) {
        const label = this.items.find((item) => {
          return item.key === value;
        })

        if (label !== undefined) {
          return label.value;
        }
      }

      return "-";
    }
  }
}
</script>

<style scoped>

</style>
